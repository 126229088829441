.searchbox {
  padding: 0;
}
.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.operationControlCurr > div {
  width: 100%;
  justify-content: space-between;
}
.operationControlCurr .el-form {
  display: flex;
}
.operationControlCurr .el-form .el-form-item {
  margin-bottom: 0;
}
.operationControlCurr .el-form .Creattime {
  display: flex;
  align-items: center;
}
.operationControlCurr .el-form .Creattime .el-form-item__content {
  margin-left: 0 !important;
}
.operationControlCurr .el-form .btns .el-form-item__content {
  margin-left: 0 !important;
}
.textRed {
  color: red;
}
.img-radio {
  display: flex;
  align-items: center;
}
.img-radio .el-radio {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.img-radio .el-radio /deep/ .el-radio__label {
  margin-bottom: 20px;
}
.img-radio .el-radio img {
  width: 400px;
}
