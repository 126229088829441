.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.operationControlCurr > div {
  width: 100%;
  justify-content: space-between;
}
.operationControlCurr .el-form {
  display: flex;
}
.operationControlCurr .el-form .el-form-item__label {
  padding: 0 8px 0 0 !important;
}
.operationControlCurr .el-form .el-form-item {
  margin-bottom: 0;
}
.operationControlCurr .el-form .btns .el-form-item__content {
  margin-left: 0 !important;
}
.textRed {
  color: red;
}
