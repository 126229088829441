












































































































































































































































































.el-input__inner {
  line-height: 32px;
  height: 32px;
}

.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
