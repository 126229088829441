

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.searchbox {
  padding: 0;
}

.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .el-form {
    display: flex;

    .el-form-item {
      margin-bottom: 0;
    }

    .Creattime {
      display: flex;
      align-items: center;

      .el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}

.textRed {
  color: red;
}

.img-radio {
  display: flex;
  align-items: center;

  .el-radio {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    /deep/ .el-radio__label {
      margin-bottom: 20px;
    }

    img {
      width: 400px;
    }
  }
}
