














































































































































































































































































































































































































































































































































































































































































































































































































.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .el-form {
    display: flex;

    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}

.textRed {
  color: red;
}
